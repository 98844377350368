<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 8H13" :stroke="fillColor" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'PriorityLowBold',
  props: {
    size: {
      type: [Number, String],
      default: 16,
    },
    fillColor: {
      type: String,
      default: '#BBBEC2',
    },
  },
};
</script>
