<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99999 7.24996L12.7333 10.8C13.1751 11.1313 13.802 11.0418 14.1333 10.6C14.4647 10.1581 14.3751 9.53133 13.9333 9.19996L8.99999 5.49996C8.4074 5.05552 7.59258 5.05551 6.99999 5.49996L2.06665 9.19996C1.62483 9.53133 1.53528 10.1581 1.86665 10.6C2.19803 11.0418 2.82483 11.1313 3.26665 10.8L7.99999 7.24996Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'PriorityMediumBold',
  props: {
    size: {
      type: [Number, String],
      default: 16,
    },
    fillColor: {
      type: String,
      default: '#E52044',
    },
  },
};
</script>
