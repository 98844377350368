<template>
  <svg :width="size" :height="size" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9ZM10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM5 8.25C5.34518 8.25 5.625 7.97018 5.625 7.625C5.625 7.27982 5.34518 7 5 7C4.65482 7 4.375 7.27982 4.375 7.625C4.375 7.97018 4.65482 8.25 5 8.25ZM4 3.99211C4 3.44667 4.44522 3 5 3C5.55478 3 6 3.44667 6 3.99211C6 4.5153 5.59024 4.94777 5.06735 4.98201C4.77081 5.00143 4.5 5.24454 4.5 5.58421V6.5H5.5V5.92134C6.36134 5.70034 7 4.92215 7 3.99211C7 2.88941 6.10208 2 5 2C3.89792 2 3 2.88941 3 3.99211H4Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 10 },
  },
};
</script>
