<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 2H12.101C11.5151 2.57412 11.0297 3.2504 10.6736 4L6 4V19.2264L7.14473 18.3871C7.48774 18.1356 7.90199 18 8.32732 18H18V13.9291C18.7061 13.8281 19.3783 13.6217 20 13.3264V18C20 19.1046 19.1046 20 18 20H8.32732L5.90955 21.7727C5.11691 22.3539 4 21.7878 4 20.805V4C4 2.89543 4.89543 2 6 2Z"
      fill="#E52044"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 2C17.9045 2 18.7529 2.24018 19.485 2.66025C20.9878 3.52262 22 5.1431 22 7C22 8.63582 21.2144 10.0882 20 11.0004C19.4186 11.4371 18.7389 11.75 18 11.9C17.6769 11.9656 17.3425 12 17 12C16.5136 12 16.0434 11.9305 15.5988 11.801C14.5482 11.4949 13.6406 10.8533 12.9996 10C12.5629 9.41859 12.25 8.73888 12.1 8C12.0344 7.67689 12 7.34247 12 7C12 6.65753 12.0344 6.32311 12.1 6C12.25 5.26112 12.5629 4.58141 12.9996 4C13.9118 2.78555 15.3642 2 17 2ZM16.3034 5.24809C16.2746 4.84385 16.5947 4.5 17 4.5C17.4053 4.5 17.7255 4.84385 17.6966 5.24809L17.5356 7.50127C17.5156 7.78228 17.2817 8 17 8C16.7183 8 16.4845 7.78228 16.4644 7.50127L16.3034 5.24809ZM17.625 9.125C17.625 8.77982 17.3452 8.5 17 8.5C16.6548 8.5 16.375 8.77982 16.375 9.125C16.375 9.47018 16.6548 9.75 17 9.75C17.3452 9.75 17.625 9.47018 17.625 9.125Z"
      fill="#E52044"
    />
    <path
      d="M12.101 12C11.7005 11.6075 11.3469 11.1672 11.0495 10.6885C11.0174 10.7865 11 10.8912 11 11C11 11.5523 11.4477 12 12 12H12.101Z"
      fill="#E52044"
    />
    <path
      d="M8 7C8 6.44772 8.44772 6 9 6C9.55228 6 10 6.44772 10 7C10 7.55228 9.55228 8 9 8C8.44772 8 8 7.55228 8 7Z"
      fill="#E52044"
    />
    <path
      d="M11 15C11 14.4477 11.4477 14 12 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H12C11.4477 16 11 15.5523 11 15Z"
      fill="#E52044"
    />
    <path
      d="M8 11C8 10.4477 8.44772 10 9 10C9.55228 10 10 10.4477 10 11C10 11.5523 9.55228 12 9 12C8.44772 12 8 11.5523 8 11Z"
      fill="#E52044"
    />
    <path
      d="M8 15C8 14.4477 8.44772 14 9 14C9.55228 14 10 14.4477 10 15C10 15.5523 9.55228 16 9 16C8.44772 16 8 15.5523 8 15Z"
      fill="#E52044"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: Number, required: false, default: 24 },
    height: { type: Number, required: false, default: 24 },
  },
};
</script>
