<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.99999 7.83333C7.59258 7.38889 8.4074 7.38889 8.99999 7.83333L13.9333 11.5333C14.3751 11.8647 14.4647 12.4915 14.1333 12.9333C13.802 13.3752 13.1751 13.4647 12.7333 13.1333L7.99999 9.58333L3.26665 13.1333C2.82483 13.4647 2.19803 13.3752 1.86665 12.9333C1.53528 12.4915 1.62483 11.8647 2.06665 11.5333L6.99999 7.83333Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.99999 2.83333C7.59258 2.38889 8.4074 2.38889 8.99999 2.83333L13.9333 6.53333C14.3751 6.8647 14.4647 7.49151 14.1333 7.93333C13.802 8.37516 13.1751 8.4647 12.7333 8.13333L7.99999 4.58333L3.26665 8.13333C2.82483 8.4647 2.19803 8.37516 1.86665 7.93333C1.53528 7.49151 1.62483 6.8647 2.06665 6.53333L6.99999 2.83333Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'PriorityHighBold',
  props: {
    size: {
      type: [Number, String],
      default: 16,
    },
    fillColor: {
      type: String,
      default: '#E52044',
    },
  },
};
</script>
